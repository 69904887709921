import { template as template_548bbfb307fc413d8be1d46d437418f1 } from "@ember/template-compiler";
const FKText = template_548bbfb307fc413d8be1d46d437418f1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
