import { template as template_c22e1a49ec6d4a6c952079998002b180 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c22e1a49ec6d4a6c952079998002b180(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
