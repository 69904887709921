import { template as template_1ea3dac5a32a488e97806d1c0940837c } from "@ember/template-compiler";
const WelcomeHeader = template_1ea3dac5a32a488e97806d1c0940837c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
